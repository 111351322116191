/*
All material copyright ESRI, All Rights Reserved, unless otherwise specified.
See https://js.arcgis.com/4.31/esri/copyright.txt for details.
*/
import { create as t } from "../core/libs/gl-matrix-2/factories/vec3f64.js";
import { getEpsilon as n, RANDOM as a } from "../core/libs/gl-matrix-2/math/common.js";
function r(t) {
  const n = t[0],
    a = t[1],
    r = t[2];
  return Math.sqrt(n * n + a * a + r * r);
}
function s(t, n) {
  return t[0] = n[0], t[1] = n[1], t[2] = n[2], t;
}
function o(t, n, a, r) {
  return t[0] = n, t[1] = a, t[2] = r, t;
}
function u(t, n, a) {
  return t[0] = n[0] + a[0], t[1] = n[1] + a[1], t[2] = n[2] + a[2], t;
}
function c(t, n, a) {
  return t[0] = n[0] - a[0], t[1] = n[1] - a[1], t[2] = n[2] - a[2], t;
}
function e(t, n, a) {
  return t[0] = n[0] * a[0], t[1] = n[1] * a[1], t[2] = n[2] * a[2], t;
}
function i(t, n, a) {
  return t[0] = n[0] / a[0], t[1] = n[1] / a[1], t[2] = n[2] / a[2], t;
}
function h(t, n) {
  return t[0] = Math.ceil(n[0]), t[1] = Math.ceil(n[1]), t[2] = Math.ceil(n[2]), t;
}
function M(t, n) {
  return t[0] = Math.floor(n[0]), t[1] = Math.floor(n[1]), t[2] = Math.floor(n[2]), t;
}
function f(t, n) {
  return t[0] = Math.abs(n[0]), t[1] = Math.abs(n[1]), t[2] = Math.abs(n[2]), t;
}
function m(t, n) {
  return t[0] = Math.sign(n[0]), t[1] = Math.sign(n[1]), t[2] = Math.sign(n[2]), t;
}
function l(t, n, a) {
  return t[0] = Math.min(n[0], a[0]), t[1] = Math.min(n[1], a[1]), t[2] = Math.min(n[2], a[2]), t;
}
function d(t, n, a) {
  return t[0] = Math.max(n[0], a[0]), t[1] = Math.max(n[1], a[1]), t[2] = Math.max(n[2], a[2]), t;
}
function b(t, n = 0, a = 1) {
  return t[0] = Math.min(Math.max(t[0], n), a), t[1] = Math.min(Math.max(t[1], n), a), t[2] = Math.min(Math.max(t[2], n), a), t;
}
function x(t, n) {
  return t[0] = Math.round(n[0]), t[1] = Math.round(n[1]), t[2] = Math.round(n[2]), t;
}
function g(t, n, a) {
  return t[0] = n[0] * a, t[1] = n[1] * a, t[2] = n[2] * a, t;
}
function q(t, n, a, r) {
  return t[0] = n[0] + a[0] * r, t[1] = n[1] + a[1] * r, t[2] = n[2] + a[2] * r, t;
}
function p(t, n) {
  const a = n[0] - t[0],
    r = n[1] - t[1],
    s = n[2] - t[2];
  return Math.sqrt(a * a + r * r + s * s);
}
function v(t, n) {
  const a = n[0] - t[0],
    r = n[1] - t[1],
    s = n[2] - t[2];
  return a * a + r * r + s * s;
}
function j(t) {
  const n = t[0],
    a = t[1],
    r = t[2];
  return n * n + a * a + r * r;
}
function y(t, n) {
  return t[0] = -n[0], t[1] = -n[1], t[2] = -n[2], t;
}
function z(t, n) {
  return t[0] = 1 / n[0], t[1] = 1 / n[1], t[2] = 1 / n[2], t;
}
function A(t, n) {
  const a = n[0],
    r = n[1],
    s = n[2];
  let o = a * a + r * r + s * s;
  return o > 0 && (o = 1 / Math.sqrt(o), t[0] = n[0] * o, t[1] = n[1] * o, t[2] = n[2] * o), t;
}
function P(t, n) {
  return t[0] * n[0] + t[1] * n[1] + t[2] * n[2];
}
function _(t, n, a) {
  const r = n[0],
    s = n[1],
    o = n[2],
    u = a[0],
    c = a[1],
    e = a[2];
  return t[0] = s * e - o * c, t[1] = o * u - r * e, t[2] = r * c - s * u, t;
}
function D(t, n, a) {
  const r = n[0],
    s = n[1],
    o = n[2],
    u = a[0],
    c = a[1],
    e = a[2],
    i = s * e - o * c,
    h = o * u - r * e,
    M = r * c - s * u,
    f = Math.sqrt(i * i + h * h + M * M);
  return t[0] = i / f, t[1] = h / f, t[2] = M / f, t;
}
function I(t, n, a, r) {
  const s = n[0],
    o = n[1],
    u = n[2];
  return t[0] = s + r * (a[0] - s), t[1] = o + r * (a[1] - o), t[2] = u + r * (a[2] - u), t;
}
function L(t, n, a, r, s, o) {
  const u = o * o,
    c = u * (2 * o - 3) + 1,
    e = u * (o - 2) + o,
    i = u * (o - 1),
    h = u * (3 - 2 * o);
  return t[0] = n[0] * c + a[0] * e + r[0] * i + s[0] * h, t[1] = n[1] * c + a[1] * e + r[1] * i + s[1] * h, t[2] = n[2] * c + a[2] * e + r[2] * i + s[2] * h, t;
}
function O(t, n, a, r, s, o) {
  const u = 1 - o,
    c = u * u,
    e = o * o,
    i = c * u,
    h = 3 * o * c,
    M = 3 * e * u,
    f = e * o;
  return t[0] = n[0] * i + a[0] * h + r[0] * M + s[0] * f, t[1] = n[1] * i + a[1] * h + r[1] * M + s[1] * f, t[2] = n[2] * i + a[2] * h + r[2] * M + s[2] * f, t;
}
function S(t, n = 1) {
  const r = a,
    s = 2 * r() * Math.PI,
    o = 2 * r() - 1,
    u = Math.sqrt(1 - o * o) * n;
  return t[0] = Math.cos(s) * u, t[1] = Math.sin(s) * u, t[2] = o * n, t;
}
function E(t, n, a) {
  const r = n[0],
    s = n[1],
    o = n[2];
  return t[0] = a[0] * r + a[4] * s + a[8] * o + a[12], t[1] = a[1] * r + a[5] * s + a[9] * o + a[13], t[2] = a[2] * r + a[6] * s + a[10] * o + a[14], t;
}
function N(t, n, a) {
  const r = n[0],
    s = n[1],
    o = n[2];
  return t[0] = r * a[0] + s * a[3] + o * a[6], t[1] = r * a[1] + s * a[4] + o * a[7], t[2] = r * a[2] + s * a[5] + o * a[8], t;
}
function Q(t, n, a) {
  const r = a[0],
    s = a[1],
    o = a[2],
    u = a[3],
    c = n[0],
    e = n[1],
    i = n[2],
    h = s * i - o * e,
    M = o * c - r * i,
    f = r * e - s * c,
    m = s * f - o * M,
    l = o * h - r * f,
    d = r * M - s * h,
    b = 2 * u;
  return t[0] = c + h * b + 2 * m, t[1] = e + M * b + 2 * l, t[2] = i + f * b + 2 * d, t;
}
function T(t, n, a, r) {
  const s = [],
    o = [];
  return s[0] = n[0] - a[0], s[1] = n[1] - a[1], s[2] = n[2] - a[2], o[0] = s[0], o[1] = s[1] * Math.cos(r) - s[2] * Math.sin(r), o[2] = s[1] * Math.sin(r) + s[2] * Math.cos(r), t[0] = o[0] + a[0], t[1] = o[1] + a[1], t[2] = o[2] + a[2], t;
}
function k(t, n, a, r) {
  const s = [],
    o = [];
  return s[0] = n[0] - a[0], s[1] = n[1] - a[1], s[2] = n[2] - a[2], o[0] = s[2] * Math.sin(r) + s[0] * Math.cos(r), o[1] = s[1], o[2] = s[2] * Math.cos(r) - s[0] * Math.sin(r), t[0] = o[0] + a[0], t[1] = o[1] + a[1], t[2] = o[2] + a[2], t;
}
function w(t, n, a, r) {
  const s = [],
    o = [];
  return s[0] = n[0] - a[0], s[1] = n[1] - a[1], s[2] = n[2] - a[2], o[0] = s[0] * Math.cos(r) - s[1] * Math.sin(r), o[1] = s[0] * Math.sin(r) + s[1] * Math.cos(r), o[2] = s[2], t[0] = o[0] + a[0], t[1] = o[1] + a[1], t[2] = o[2] + a[2], t;
}
function B(t, n) {
  A(C, t), A(F, n);
  const a = P(C, F);
  return a > 1 ? 0 : a < -1 ? Math.PI : Math.acos(a);
}
const C = t(),
  F = t();
function G(t) {
  return "vec3(" + t[0] + ", " + t[1] + ", " + t[2] + ")";
}
function H(t, n) {
  return t[0] === n[0] && t[1] === n[1] && t[2] === n[2];
}
function J(t, a) {
  if (t === a) return !0;
  const r = t[0],
    s = t[1],
    o = t[2],
    u = a[0],
    c = a[1],
    e = a[2],
    i = n();
  return Math.abs(r - u) <= i * Math.max(1, Math.abs(r), Math.abs(u)) && Math.abs(s - c) <= i * Math.max(1, Math.abs(s), Math.abs(c)) && Math.abs(o - e) <= i * Math.max(1, Math.abs(o), Math.abs(e));
}
function K(t, n, a) {
  const r = a[0] - n[0],
    s = a[1] - n[1],
    o = a[2] - n[2];
  let u = r * r + s * s + o * o;
  return u > 0 ? (u = 1 / Math.sqrt(u), t[0] = r * u, t[1] = s * u, t[2] = o * u, t) : (t[0] = 0, t[1] = 0, t[2] = 0, t);
}
const R = c,
  U = e,
  V = i,
  W = p,
  X = v,
  Y = r,
  Z = j,
  $ = Object.freeze(Object.defineProperty({
    __proto__: null,
    abs: f,
    add: u,
    angle: B,
    bezier: O,
    ceil: h,
    clamp: b,
    copy: s,
    cross: _,
    crossAndNormalize: D,
    direction: K,
    dist: W,
    distance: p,
    div: V,
    divide: i,
    dot: P,
    equals: J,
    exactEquals: H,
    floor: M,
    hermite: L,
    inverse: z,
    len: Y,
    length: r,
    lerp: I,
    max: d,
    min: l,
    mul: U,
    multiply: e,
    negate: y,
    normalize: A,
    random: S,
    rotateX: T,
    rotateY: k,
    rotateZ: w,
    round: x,
    scale: g,
    scaleAndAdd: q,
    set: o,
    sign: m,
    sqrDist: X,
    sqrLen: Z,
    squaredDistance: v,
    squaredLength: j,
    str: G,
    sub: R,
    subtract: c,
    transformMat3: N,
    transformMat4: E,
    transformQuat: Q
  }, Symbol.toStringTag, {
    value: "Module"
  }));
export { l as A, m as B, e as C, d as D, i as E, W as F, Y as G, J as H, $ as I, M as J, w as K, b as L, h as M, x as N, D as O, L as P, O as Q, S as R, T as S, k as T, G as U, U as V, V as W, R as a, q as b, s as c, c as d, _ as e, P as f, u as g, g as h, o as i, p as j, j as k, r as l, I as m, A as n, K as o, H as p, N as q, B as r, v as s, E as t, z as u, y as v, Q as w, f as x, X as y, Z as z };